import React, { useState, useEffect } from 'react';
import { parseISO, format } from 'date-fns';
import Page from 'components/Templates/Page';
import api from 'services/api';
import Avatar from 'components/Avatar';
import './style.css';
import Pagination from "react-js-pagination";
import InputSearch from 'components/InputSearch';
import Msg from 'components/Msg';
import TagActive from 'components/TagActive';
import Switch from 'components/Switch';
import { useHistory } from "react-router-dom";
import BtnTitle from 'components/BtnTitle';

export default function ListUsers() {
    const history = useHistory();
    const [clients, setClients] = useState([])
    const [isLoading, setIsLoading] = useState(true);
    const [paginate, setPaginate] = useState({});
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState('');
    const [msg, setMsg] = useState({ message: '', type: '' });

    const loadClients = async () => {
        try {
            let response;

            if (search.length > 0) {
                response = await api.get(`/admin/clients?page=${page.toString()}&search=${search}`);
            } else {
                response = await api.get(`/admin/clients?page=${page.toString()}`);
            }
            setClients(response.data);
            setIsLoading(false);
            setPaginate(response.paginate);

        } catch (error) {
            setIsLoading(false);
            // setMsg({message: 'Falha ao listar os useres.', type: 'error'});
            console.log(error)
        }
    }

    useEffect(() => {
        const initLoad = async () => {
            try {
                let response;

                if (search.length > 0) {
                    response = await api.get(`/admin/clients?page=${page.toString()}&search=${search}`);
                } else {
                    response = await api.get(`/admin/clients?page=${page.toString()}`);
                }
                setClients(response.data);
                setIsLoading(false);
                setPaginate(response.paginate);

            } catch (error) {
                setIsLoading(false);
                // setMsg({message: 'Falha ao listar os useres.', type: 'error'});
                console.log(error)
            }
        }

        initLoad()
    }, [page, search])


    const remove = async (client) => {
        var response = window.confirm(`Ao remover a conta "${client.name}", todos os dados serão perdidos. Tem certeza que quer deletar essa conta?`);
        if (response === true) {
            await api.delete(`/admin/clients/${client._id}`);
            loadClients();
        }
    }

    const resetPassword = async (client) => {
        var response = window.confirm(`Resetar a senha de "${client.name}" para o padrão "123123"?`);
        if (response === true) {
            await api.post(`/admin/clients/reset_password`, { email: client.email });
            setMsg({ message: `A senha de "${client.name}" foi alterada para "123123" com sucesso. Enviamos uma e-mail contendo a nova senha.`, type: 'success' });
        }
    }


    const activeAccount = async (client) => {
        var response = window.confirm(`Gostaria de mudar o status da assinatura de "${client.name}", para ${client.active ? 'Inativo' : 'Ativo'}?`);
        if (response === true) {
            await api.put(`/admin/clients/${client._id}/status`, { status: client.active ? 'SUSPENDED' : 'ACTIVE' });
            loadClients();
        }
    }

    return (
        <Page name="clients" loading={isLoading}>
            <h1>Clientes
                <BtnTitle label="Adicionar" iconName="plus-circle" handleOnClick={() => history.push('/ondemand/clients/add')} />
            </h1>
            {msg.message ? <Msg type={msg.type} message={msg.message} /> : null}

            <InputSearch placeholder="Buscar..." handleValue={search} handleOnChange={(e) => setSearch(e.target.value)} />
            <div className="container">
                <table className="uk-table uk-table-striped uk-table-small  uk-table-middle">
                    <thead>
                        <tr>
                            <th width="20">#</th>
                            <th width="40">Avatar</th>
                            <th>Nome</th>
                            <th>Email</th>
                            <th>Conta criada em:</th>
                            <th width="30">Ativa?</th>
                            <th width="20">#</th>
                            <th width="20">#</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            clients.map((client, index) => (
                                <tr key={index}>
                                    <td><TagActive active={client.active} /></td>
                                    <td><Avatar name={client.name} image={client.avatar} size={28} /></td>
                                    <td>{client.name}</td>
                                    <td>{client.email}</td>
                                    <td><i className='bx bx-calendar'></i>{client?.createdAt && format(parseISO(client.createdAt), 'dd/M/Y')}</td>
                                    <td align="center"><Switch handleChecked={client?.active} handleChange={() => activeAccount(client)} /></td>
                                    <td align="center"><span onClick={() => resetPassword(client)} className="iconDefault"><i className='bx bx-lock' uk-tooltip="Resetar Senha"></i></span></td>
                                    <td align="center"><span onClick={() => remove(client)} className="iconRemove"><i className='bx bx-trash' uk-tooltip="Remover Conta" ></i></span></td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>

            <Pagination
                innerClass="pagination"
                activePage={Number(paginate.page)}
                totalItemsCount={Number(paginate.total)}
                pageRangeDisplayed={10}
                hideNavigation={true}
                hideFirstLastPages={true}
                onChange={(page) => {
                    setPage(page);
                }}
            />

        </Page>
    )
}

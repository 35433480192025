import React from 'react';
import styles from './style.module.css'
export default function ProgressBar(props) {
   const { start, end, bgColor } = props;
   const percentage = (start / end) * 100;

   return (
      <div className={styles.progressbar}>
         <label>{start}</label>
         <div><span style={{ width: `${percentage}%`, backgroundColor: `${bgColor ? bgColor : '#19B3EA'}` }}></span></div>
         <label>{end}</label>
      </div>
   )
}
import React from 'react';
import styles from  './style.module.css';

const EmptyState = (props) => {
  const {title, message, icon} = props;
  return (
    <div className={styles.content}>
      {
        icon && <div className={styles.icon}><i className={`bx bx-${icon}`}></i></div>
      }
      <h4>{title}<br/><small>{message}</small></h4>
      
    </div>
  );
}

export default EmptyState;
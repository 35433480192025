import React from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

//Páginas
import Dashboard from './../pages/Dashboard';

import Logs from './../pages/Logs';

import MyAccount from './../pages/MyAccount';
import UpdatePassword from './../pages/UpdatePassword';



// Sales

import Sellers from './../pages/Ondemand/Sales/Sellers/ListSellers';
import AddSeller from './../pages/Ondemand/Sales/Sellers/AddSeller';
import EditSeller from './../pages/Ondemand/Sales/Sellers/EditSeller';

import HistorySales from './../pages/Ondemand/Sales/History/ListHistory';

import Commissions from './../pages/Ondemand/Sales/Commissions/ListCommissions';


import Clients from './../pages/Ondemand/Clients/ListClients';



import Notifications from '../pages/Ondemand/Notifications/ListNotifications';


export default function Private() {
    return (
        <Router>
            <Switch>
                <Route exact path='/dashboard' component={Dashboard} />

                <Route exact path='/logs' component={Logs} />

                <Route exact path='/my-account' component={MyAccount} />
                <Route exact path='/update-password' component={UpdatePassword} />

                <Route exact path='/ondemand/clients' component={Clients} />

                <Route exact path='/ondemand/notifications' component={Notifications} />
                
                <Route exact path='/ondemand/sales/sellers' component={Sellers} />
                <Route exact path='/ondemand/sales/sellers/add' component={AddSeller} />
                <Route exact path='/ondemand/sales/sellers/edit/:_id' component={EditSeller} />

                <Route exact path='/ondemand/sales/history' component={HistorySales} />

                <Route exact path='/ondemand/sales/commissions' component={Commissions} />

                <Route path='/*' component={Dashboard} />

            </Switch>
        </Router>
    )
}
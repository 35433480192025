import React from 'react';
import ReactSwitch from "react-switch";
import styles from './style.module.css';

function Switch(props) {

   const { text, handleChange, handleChecked } = props

   return (
      <div>
         <label className={styles.content}>
            <ReactSwitch onChange={handleChange} checked={handleChecked} height={16} width={32} checkedIcon={false} uncheckedIcon={false} />
            {text && <span>{text}</span>}
         </label>
      </div>
   );
}

export default Switch;
import React from 'react';
import './style.css';

function TagActive(props) {

  const { label, active, handleOnClick } = props;

  if (label) {
    return <p className={`tag ${active ? 'tagActive' : 'tagInactive'}`} onClick={handleOnClick}>{label}</p>
  } else {
    return <p className={`bullet ${active ? 'tagActive' : 'tagInactive'}`} onClick={handleOnClick}>{label}</p>
  }


}

export default TagActive;
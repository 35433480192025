import React, { useState, useEffect } from 'react';
import { parseISO, format } from 'date-fns';
import Page from 'components/Templates/Page';
import api from 'services/api';
import { useHistory, Link } from "react-router-dom";
import BtnTitle from 'components/BtnTitle';
import Avatar from 'components/Avatar';
import TagActive from 'components/TagActive';

export default function Notifications() {
    const history = useHistory();
    const [notifications, setNotifications] = useState([]);
    const [isLoading, setIsLoading] = useState(true);


    const loadNotifications = async () => {
        setIsLoading(true);
        try {
            const response = await api.get(`/admin/ondemand/notifications`);
            setNotifications(response.data);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        loadNotifications();
    }, []);


    const remove = async (_id) => {
        var response = window.confirm("Tem certeza que quer remover essa notificação?");
        if (response === true) {
            await api.delete(`/admin/ondemand/notifications/${_id}`);
            history.replace('/ondemand/notifications')
            loadNotifications();
        }
    }


    return (
        <Page name="ondemand" loading={isLoading}>
            <h1>Notificações
                <BtnTitle label="Adicionar" iconName="plus-circle" handleOnClick={() => history.push('/ondemand/notifications/add')} />
            </h1>

            <div className="container">
                <table className="uk-table uk-table-striped uk-table-small  uk-table-middle">
                    <thead>
                        <tr>

                            <th>Conteúdo</th>
                            <th width="40">Avatar</th>
                            <th>Usuário</th>
                            <th>Criado em</th>
                            <th>Publicação</th>
                            <th width="40" align="center">Todos</th>
                            <th width="40">#</th>
                            <th width="40">#</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            notifications.map((notification, index) => (
                                <tr key={index}>
                                    <td><Link to={`notifications/edit/${notification?._id}`}>{notification?.content}</Link></td>
                                    <td>{!!notification.user ? <Avatar name={notification.user?.name} image={notification.user?.avatar} size={28} /> : '-'}</td>
                                    <td>{!!notification.user ? notification?.user?.name : '-'}</td>
                                    <td><i className='bx bx-calendar'></i>{format(parseISO(notification.createdAt), 'dd/M/Y')} às {format(parseISO(notification.createdAt), 'HH:mm')}hrs</td>
                                    <td><i className='bx bx-calendar'></i>{!!notification.publication ? `${format(parseISO(notification.publication), 'dd/M/Y')} às ${format(parseISO(notification.publication), 'HH:mm')}hrs` : '-'}</td>
                                    <td align="center"><TagActive active={notification.all} /></td>
                                    <td align="center"><span onClick={() => remove(notification._id)} className="iconRemove"><i className='bx bx-trash'></i></span></td>
                                    <td align="center"><Link to={`notifications/edit/${notification._id}`}><i className='bx bx-pencil' uk-tooltip="Editar Notificação" style={{ fontSize: '17px', display: 'flex' }} ></i></Link></td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>

            </div>

        </Page>
    )
}

import React, { useState, useEffect } from 'react';
import Page from 'components/Templates/Page';
import api from 'services/api';
import Avatar from 'components/Avatar';
import { parseISO, format } from 'date-fns';
import styles from './style.module.css';
import { CardDefault } from 'components/Cards';
import ProgressBar from 'components/ProgressBar';
import figura from 'assets/images/figura.png';
import { getAuthUser } from 'services/auth';
import EmptyState from 'components/EmptyState';

export default function Dashboard() {

    const [user, setUser] = useState({})

    const [dashboard, setDashboard] = useState([])
    const [analytics, setanalytics] = useState([])
    // const [commissions, setCommissions] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {

        const loadDasboard = async () => {
            try {
                const response = await api.get(`/seller/dashboards`);
                setDashboard(response);
                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
                // setMsg({message: 'Falha ao listar os logs.', type: 'error'});
            }
        }
        loadDasboard();

        async function getUser(){
            const user = await getAuthUser();
            setUser(user);
        }
        getUser();


        const loadHistoryCommissions = async () => {
            setIsLoading(true);
            
            try {
                // let response = await api.get(`/seller/commissions`);
                let resultAnalytics = await api.get(`/seller/commissions/analytics`);
                // setCommissions(response.data);
                setanalytics(resultAnalytics.data)
                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
            }
        }
        loadHistoryCommissions();


    }, []);


    return (
        <Page name="dashboard" loading={isLoading}>
            <h1>Dashboard</h1>

            

            <CardDefault handleClass={styles.cardProgress}>
                <div className={styles.areaFigura}>
                    <img src={figura} alt="Figura mostrando a importância do seu rankeamento"/>
                </div>

                <div className={styles.areaInfo}>
                    <h3>Você está no nível: <b>{user?.saleProfile?.name}</b></h3>
                    <div className={styles.areaProgress}>
                        <h4>Vendas</h4>
                        <ProgressBar start={dashboard?.countSale?.count} end={user?.saleProfile?.totalSalesForAStart} />
                        <small>Faltam <b>{(user?.saleProfile?.totalSalesForAStart - dashboard?.countSale?.count)}</b> vendas para conquistar uma estrela.</small>
                    </div>

                    <div className={styles.areaProgress}>
                        <h4>Próximo nível</h4>
                        <ProgressBar start={user.stars} end={user?.saleProfile?.numberStarsForNextLevel} bgColor="#fdc500" />
                        <small>Faltam <b>{(user?.saleProfile?.numberStarsForNextLevel - user.stars)}</b> estrelas para atingir o próximo nível e aumentar o valor de suas comissões.</small>
                    </div>

                    <div className={styles.areaProgress}>
                        <h4>Link de divulgação</h4>
                        <small>Copie o link, divulgue e comece a vender nossa plataforma.</small>
                        <div className={styles.areaLink}>https://unitedondemand.com.br/signup?code={user.code}</div>
                        <div className={styles.btnclipboard} onClick={()=> {
                             navigator.clipboard.writeText(`https://unitedondemand.com.br/signup?code=${user.code}`);
                             alert('Link copiado.');
                            }}><i className='bx bx-clipboard'></i> Copiar link</div>
                    </div>
                </div>
            </CardDefault>


            <div className='areaInfo'>
                {
                    analytics.map((item, index) => {
                        let valor = Intl.NumberFormat('pt-br', { style: 'currency', currency: 'BRL' }).format(item.totalAmount)
                    return <div className="container cardDash" key={index}>
                        
                        <h3>Comissões {item._id ? 'Pagas' : 'em Aberto'}</h3>
                        <h1>{valor}</h1>
                        <small>Total de <b>{item.count}</b> comissões</small>
                    </div>
                    })
                }
            </div>


            <div className='areaInfo'>

                <div className='container cardDash'>
                <h3>Últimas vendas</h3>

                {
                    dashboard.sales && dashboard.sales.length === 0 ?
                    <EmptyState title="Vazio" message="Você ainda não realizou nenhuma venda." icon="wallet"/>
                    :
                    <table className="uk-table uk-table-striped uk-table-small  uk-table-middle">
                    <thead>
                        <tr>
                            <th>Cliente</th>
                            <th>Vendedor</th>
                            <th>Plano</th>
                            <th>Quando</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            dashboard?.sales?.map((item, index) => {

                                let valor = Intl.NumberFormat('pt-br', { style: 'currency', currency: 'BRL' }).format(item.plan.amountPerPayment)
                                let user = item.user[0];
                                let seller = item.seller[0];

                                return  <tr key={index}>
                                    <td>
                                        {user ? <div className='cardProfile'>
                                            <Avatar name={user.name} image={user.avatar} size={28} />
                                            <span>
                                                <h4>{user.name}</h4>
                                                <small>{user.email}</small>
                                            </span>
                                        </div> : '-'}
                                    </td>
                                    <td>
                                    {seller ? <div className='cardProfile'>
                                            <Avatar name={seller.name} image={seller.avatar} size={28} />
                                            <span>
                                                <h4>{seller.name}</h4>
                                                <small>{seller.code}</small>
                                            </span>
                                        </div> : '-'}
                                    </td>
                                    <td>
                                        <p>{item.plan.name}<br/><small>{valor}</small></p>
                                    </td>
                                    <td><i className='bx bx-calendar'></i>{format(parseISO(item.createdAt), 'dd/MM/Y - H:m')}</td>
                                    
                                </tr>

                            })
                        }
                    </tbody>
                </table>
                }
                

                </div>

            </div>


            <div className="areaInfo">

                <div className="container cardDash">
                    <h3>Últimas atividades</h3>
                    <table className="uk-table uk-table-striped uk-table-small  uk-table-middle">
                        <thead>
                            <tr>
                                <th width="20">#</th>
                                <th>Usuário</th>
                                <th>Descrição</th>
                                <th>Quando?</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                dashboard?.logs?.map((log, index) => {

                                    return (
                                        <tr key={index}>
                                            <td><Avatar name={log?.user?.name || '?'} image={log?.user?.avatar} size={28} /></td>
                                            <td><p>{log?.user?.name || 'Usuário não identificado'}</p></td>
                                            <td><p>{log.description}</p></td>
                                            <td><p><i className='bx bx-calendar'></i>{format(parseISO(log.createdAt), 'dd/M/Y')} às {format(parseISO(log.createdAt), 'HH:mm')}hrs</p></td>

                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>



            </div>
            

        </Page>
    )
}

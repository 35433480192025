import React from 'react';
import styles from './style.module.css';

const InputSearch = (props) => {
  const { placeholder, autofocus, handleValue, handleOnChange } = props;
  return (
    <div className={styles.fieldset}>
      <input type="text" placeholder={placeholder} className={styles.campos} autoFocus={autofocus} value={handleValue} onChange={handleOnChange} />
      <i className="bx bx-search"></i>
    </div>
  );
}

export default InputSearch;
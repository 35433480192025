import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import logo from 'assets/images/logo_black.png';
import { getAuthUser, Logout } from 'services/auth';
import Avatar from 'components/Avatar';
import { useDispatch, useSelector } from 'react-redux';
import Loading from 'components/Loading';

import './style.css';


export default function Page(props) {

    const { name, loading } = props;

    const [showMenu, setShowMenu] = useState(false)
    const [userLogged, setUserLogged] = useState({});
    

    const logged = useSelector(state => state.loginState.userLogged);
    const dispatch = useDispatch();

    const logout = () => {
        dispatch({ type: 'SET_LOGGED', isLogged: false })
        Logout();
    }

    useEffect(() => {
        const getUserLogged = async () => {
            const user = await getAuthUser()
            setUserLogged(user);
        }
        getUserLogged()
    }, [logged])

    return (
        <>
            <div className="header">
                <img src={logo} alt="Phast" />
                
                <div className="areaSubMenu">
                    
                    <div className="areaProfile">
                        <div className="infoProfile">
                            <p>{userLogged?.name} <br /> <small><b>Nível:</b> {userLogged?.saleProfile?.name} - <i className='bx bx-star' style={{color: '#fdc500'}}></i> <b>{userLogged?.stars}</b></small></p>
                        </div>
                        <Avatar image={userLogged?.avatar} name={userLogged?.name} size={40} />
                    </div>

                    <div className="submenu animated fadeIn">
                        <Link to="/my-account"><li className={name === 'my-account' ? 'active' : ''}><span>Minha Conta</span><i className='bx bxs-user-detail'></i></li></Link>
                        <Link to="/update-password"><li className={name === 'update-password' ? 'active' : ''}><span>Alterar Senha</span><i className='bx bx-lock-open-alt'></i></li></Link>
                        <a href="/" onClick={logout}><li className={name === 'logout' ? 'active' : ''}><span>Logout</span><i className='bx bx-log-out' ></i></li></a>
                    </div>

                </div>

                <div className="btnMenu animated fadeIn" onClick={() => setShowMenu(!showMenu)}>
                    <i className='bx bx-menu'></i>
                </div>
            </div>

            {
                showMenu &&
                <div className="menuMobi animated fadeIn">
                    <div className="contentAside">
                        <ul>
                            <Link to="/dashboard"><li className={name === 'dashboard' ? 'active' : ''}><i className='bx bxs-dashboard'></i> <span>Dashboard</span></li></Link>
                            <Link to="/ondemand/sales/history"><li className={name === 'history' ? 'active' : ''}><i className='bx bx-store' ></i> <span>Minhas de Vendas</span></li></Link>
                            <Link to="/ondemand/sales/commissions"><li className={name === 'commissions' ? 'active' : ''}><i className='bx bx-wallet-alt'></i> <span>Comissões</span></li></Link>
                            <Link to="/ondemand/sales/sellers"><li className={name === 'sellers' ? 'active' : ''}><i className='bx bx-group' ></i> <span>Agentes</span></li></Link>
                            <Link to="/logs"><li className={name === 'logs' ? 'active' : ''}><i className='bx bx-task' ></i> <span>Logs</span></li></Link>
                        </ul>
                    </div>
                </div>
            }



            <div className="pagina">
                <div className="aside">
                    <div className="contentAside">
                        <ul>
                            <Link to="/dashboard"><li className={name === 'dashboard' ? 'active' : ''}><i className='bx bxs-dashboard'></i> <span>Dashboard</span></li></Link>
                            <Link to="/ondemand/sales/history"><li className={name === 'history' ? 'active' : ''}><i className='bx bx-store' ></i> <span>Minhas de Vendas</span></li></Link>
                            <Link to="/ondemand/sales/commissions"><li className={name === 'commissions' ? 'active' : ''}><i className='bx bx-wallet-alt'></i> <span>Comissões</span></li></Link>
                            <Link to="/ondemand/sales/sellers"><li className={name === 'sellers' ? 'active' : ''}><i className='bx bx-group' ></i> <span>Agentes</span></li></Link>
                            <Link to="/logs"><li className={name === 'logs' ? 'active' : ''}><i className='bx bx-task' ></i> <span>Logs</span></li></Link>
                        </ul>
                    </div>
                </div>


                <div className="content animated fadeIn">
                    {loading ? <Loading /> : props.children}
                </div>

            </div>
        </>
    );
}
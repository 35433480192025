import React from 'react';
import Loader from 'react-loader-spinner';
import styles from './style.module.css';

const BtnInline = (props) => {
  const { label, handleOnClick, isLoading, handleType, handleStyle } = props;

  if (isLoading) {
    return <div className={styles.btnInline}><Loader type="TailSpin" color="#666" height={16} width={16} /></div>;
  } else {
    return <button className={styles.btnInline} style={handleStyle} onClick={handleOnClick} type={handleType}>{label}</button>;
  }
}

export default BtnInline;
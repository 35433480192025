import React from 'react';

import './style.css';

// Icons from UIKit

function BtnTitle(props) {
  const { label, iconName, iconColor, handleOnClick } = props;
  return (
    <div className="areaBtnTitle">
      <button className="btnTitle" onClick={handleOnClick}><i uk-icon={iconName} style={{ color: iconColor }}></i><span>{label}</span></button>
    </div>
  );
}

export default BtnTitle;
import React, { useState, useEffect } from 'react';
import { parseISO, format } from 'date-fns';
import Page from 'components/Templates/Page';
import api from 'services/api';
import Avatar from 'components/Avatar';
import Pagination from "react-js-pagination";
import { useHistory, Link } from "react-router-dom";
import BtnTitle from 'components/BtnTitle';

export default function Sellers() {
    const history = useHistory();
    const [sellers, setSellers] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const [paginate, setPaginate] = useState({});
    const [page, setPage] = useState(1);

    useEffect(() => {
        const loadSellers = async () => {
            setIsLoading(true);
            try {
                const response = await api.get(`seller/seller-childrens?page=${page.toString()}`);
                setSellers(response.data);
                setPaginate(response.paginate);
                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
            }
        }
        loadSellers();
    }, [page]);

    return (
        <Page name="sales" loading={isLoading}>
            <h1>Agentes
                <BtnTitle label="Adicionar" iconName="plus-circle" handleOnClick={() => history.push('/ondemand/sales/sellers/add')} />
            </h1>

            <div className="container">
                <table className="uk-table uk-table-striped uk-table-small  uk-table-middle">
                    <thead>
                        <tr>
                            <th width="28">#</th>
                            <th>Nome</th>
                            <th>Code</th>
                            <th>Email</th>
                            <th>Perfil de Venda</th>
                            <th width="130">Criado em:</th>
                            <th width="20">#</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            sellers.map((item, index) => (
                                <tr key={index}>
                                    <td><Avatar name={item.name} image={item.avatar} size={28} /></td>
                                    <td><Link to={`/ondemand/sales/sellers/edit/${item._id}`}>{item.name}</Link></td>
                                    <td>{item.code}</td>
                                    <td>{item.email}</td>
                                    <td>{item.saleProfile?.name}</td>
                                    <td><i className='bx bx-calendar'></i>{format(parseISO(item.createdAt), 'dd/M/Y')}</td>
                                    <td align="center"><Link to={`/ondemand/sales/sellers/edit/${item._id}`}><i className='bx bx-pencil' uk-tooltip="Editar perfil de venda" style={{ fontSize: '17px', display: 'flex' }} ></i></Link></td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>

            </div>

            <Pagination
                innerClass="pagination"
                activePage={Number(paginate.page)}
                totalItemsCount={Number(paginate.total)}
                pageRangeDisplayed={10}
                hideNavigation={true}
                hideFirstLastPages={true}
                onChange={(page) => {
                    setPage(page);
                }}
            />


        </Page>
    )
}

import React, { useState, useEffect } from 'react';
import Page from 'components/Templates/Page';
import Msg from 'components/Msg';
import BtnTitle from 'components/BtnTitle';
import Fieldset from 'components/Fieldset';
import Button from 'components/Button';
import api from 'services/api';

import { useHistory } from "react-router-dom";
import Switch from 'components/Switch';
import Voucher from 'voucher-code-generator';

import './style.css';

export default function AddSeller() {

    const history = useHistory();

        
    const [name, setname] = useState('')
    const [email, setemail] = useState('')
    const [code, setcode] = useState('')
    const [region, setregion] = useState('')
    const [permitedSonSeller, setpermitedSonSeller] = useState(true)
    const [parentId, setparentId] = useState('')
    

    const [isLoading, setIsLoading] = useState(false);
    const [msg, setMsg] = useState({ message: '', type: '' });



    useEffect(() => {
   
        // Generate code
        let code = name.slice(0, 3);
        if (name.length >= 3) {
            const voucher = Voucher.generate({
                length: 3,
                count: 1,
                prefix: code
            });

            setcode(voucher[0].toUpperCase())
            
        }

    }, [name]);



    const add = async () => {

        setIsLoading(true);

        try {
            await api.post('seller/seller-childrens', { 
                name,
                email,
                code,
                region,
                permitedSonSeller,
                parentId
             });
            setIsLoading(false);
            setMsg({ message: 'Vendedor adicionado com sucesso.', type: 'success' });
            
            setname('')
            setemail('')
            setcode('')
            setregion('')
            setpermitedSonSeller(false)
            setparentId('')

        } catch (error) {
            setIsLoading(false);
            if (error.response?.data.msg) {
                const msg = error.response.data.msg;
                setMsg({ message: msg, type: 'error' });
                return;
            }
            setMsg({ message: 'Falha de conexão.', type: 'error' });
        }
    }

    


    return (
        <Page name="sales">
            <h1>
                Adicionar Agente
                <BtnTitle label="Voltar" iconName="chevron-left" handleOnClick={() => history.goBack()} />
            </h1>


            <div className="informacoes">

                <div className="container">
                    <h3>Informações</h3>
             

                    <Fieldset label="Nome" type="text" placeholder="Digite um nome para o perfil." handleValue={name} handleOnChange={(e) => setname(e.target.value)} />
                    <Fieldset label="Código Vendedor" type="text" placeholder="" handleValue={code} handleOnChange={(e) => setcode(e.target.value)} />
                    <Fieldset label="E-mail" type="text" placeholder="" handleValue={email} handleOnChange={(e) => setemail(e.target.value)} />
                    <Fieldset label="Região" type="text" placeholder="" handleValue={region} handleOnChange={(e) => setregion(e.target.value)} />
                    <hr/>
                    <Switch text="Permitir que o esse vendedor cadastre vendedores filhos?" handleChange={setpermitedSonSeller} handleChecked={permitedSonSeller}  />
                    
                    
                    
                </div>

            </div>

            { msg.message ? <Msg type={msg.type} message={msg.message} /> : null}

            <div className="areaBtnPlan">
                <Button label="Adicionar Vendedor" isLoading={isLoading} handleOnClick={add} handleType="submit" />
            </div>

        </Page>
    )
}
